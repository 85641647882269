import React, { Component } from 'react';
import homedata from '../../data/home.json';

class EKYC extends Component {
    render () {

        let eKYCInfo = homedata.eKYC
        let sectionHeadingInfo = eKYCInfo.sectionHeading
        let features = eKYCInfo.features


        return  <section id="solutions-features-ekyc" className="pt100 pb100 bg-light-gray">
        <div className="container">
            <div className="row">
                <div className="col-md-4 col-12">
                    <div className="section-title  mb60">
                        <h2 className="text-left" dangerouslySetInnerHTML={{__html: sectionHeadingInfo.title}}></h2>
                        <hr className="lines text-left"/>
                        <p className="text-left">{sectionHeadingInfo.desc}</p>
                        <div className="pt-5 d-flex">
                            <img className="solutions-img m-auto " src={sectionHeadingInfo.img} alt={sectionHeadingInfo.title} />
                        </div>
                    </div>
                </div>

                <div className="col-md-8 col-12">
                    <div className="feature-list-item">
                        {
                            features.map( ( info, i ) => {
                                return(
                                        <div className="flex-column h-90">
                                            {/* <div className="media-icon m-auto">
                                                <i className={info.icon}></i>
                                            </div> */}
                                            <div className="media-body m-20px-t">
                                                <h5 >{info.featureTitle}</h5>
                                                <p>{info.featureDesc}</p>
                                            </div>
                                        </div>
                                )
                            } )
                        }

                    </div>
                </div>
            </div>
        </div>
    </section>
    }
}


export default EKYC
