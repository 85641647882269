import React, { Component } from 'react';
import homedata from '../../data/home.json';

class TransactionMonitoringMoreInfo extends Component {
    render () {

        let moreInfo = homedata.transactionMonitoring.moreInfo
        let desc = moreInfo.desc


        return     <section id="solutions-features-transaction-monitoring" className="pt100 bg-light-gray">
        <div className="container">
            <div className="row">
                <div className="col-md-5 col-12">
                    <div className="section-title mb60">
                        <h2 className="text-left" dangerouslySetInnerHTML={{__html: moreInfo.title}}></h2>
                        <hr className="lines text-left"/>
                        <div className="pt-5 d-flex">
                            <img className="solutions-img m-auto" src={moreInfo.img2} alt={moreInfo.title} />
                        </div>
                    </div>
                </div>
                <div className="col-md-7 col-12 my-auto">
                    <div className="py-5">
                        {
                            <div className="flex-column h-90 pt-3">
                                <div className="media-body m-20px-t">
                                    <p>{desc[1]}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-7 col-12">
                    <div className="p-5">
                        {
                            <div className="flex-column h-90 pt-3">
                                {/* <div className="media-icon m-auto">
                                    <i className={info.icon}></i>
                                </div> */}
                                <div className="media-body m-20px-t">
                                    <p>{desc[0]}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-md-5 col-12">
                    <div className="section-title mb60">
                        <div className="pt-5 d-flex ">
                            <img className="solutions-img m-auto w-75" src={moreInfo.img1} alt={moreInfo.title} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
}


export default TransactionMonitoringMoreInfo
