import React, { Component } from 'react';
import homedata from '../../data/home.json';
import { HashLink as HLink } from 'react-router-hash-link';

class FeaturesSolutions extends Component {
    render() {

        let solutions = homedata.solutions
        let getSpecialFeatures = homedata.specialFeatures
        let aboutFeatures = solutions.features
        let publicUrl = process.env.PUBLIC_URL

        const inlineStyle = {
            backgroundImage : 'url('+publicUrl+getSpecialFeatures.bgImg+')'
        }

        return <section id="solutions-features" className="pt50 pb50 bg-parallax" style={inlineStyle}>
        <div className="color-overlay opacity-8"></div>
        <div className="container">
        <div className="row about-boxes justify-content-center">
                {
                    aboutFeatures.map( ( info, i ) => {
                        return(
                            <div key={i} className="col-lg-4 col-md-6">
                                <div className="media flex-column h-90 ">
                                    <div className="media-icon m-auto">
                                        <img src={info.icon} alt=""/>
                                    </div>
                                    <div className="media-body m-20px-t">
                                        <h5>{info.title}</h5>
                                        <p>{info.desc}</p>
                                    </div>
                                    <div className="download-btn m-auto pt-5">
                                        <HLink className="read-more primary-btn primary-btn-2" smooth  to={info.btnUrl}>{getSpecialFeatures.btnLabel}</HLink>
                                    </div>
                                   
                                </div>
                            </div>
                        )
                    } )
                }

            </div>
        </div>
        <div className="bottom-pattern d-none d-lg-block filter-light-blue" >
            <img src={`${publicUrl}/assets/img/bg-pattern-light.png`} alt="ng pattren" />
        </div>
    </section>
    }
}


export default FeaturesSolutions
