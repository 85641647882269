import React, { Component } from 'react';
import homedata from '../../data/home.json';

class AmlScreening extends Component {
    render () {

        let amlScreeningInfo = homedata.amlScreening
        let sectionHeadingInfo = amlScreeningInfo.sectionHeading
        let features = amlScreeningInfo.features


        return <section id="solutions-features-aml-screening" className="pt100 pb100">
        <div className="container">
            <div className="row">
                        <div className="col-md-5 col-12">
                            <div className="section-title  mb60">
                                <h2 className="text-left" dangerouslySetInnerHTML={{__html: sectionHeadingInfo.title}}></h2>
                                <hr className="lines text-left"/>
                                <p className="text-left">{sectionHeadingInfo.desc}</p>
                            </div>
                        </div>

                        <div className="col-md-7 col-12">
                            <div className="d-flex">
                                <img className="solutions-img w-75 m-auto cover-img" src={sectionHeadingInfo.img} alt={sectionHeadingInfo.title} />
                            </div>
                        </div>
            </div>
            <div className="row">
            {
                features.map( ( info, i ) => {
                    return(
                            <div className="col-12 col-md-6">
                                <div className="flex-column h-90">
                                    {/* <div className="media-icon m-auto">
                                        <i className={info.icon}></i>
                                    </div> */}
                                    <div className="media-body m-20px-t">
                                        <h5 >{info.featureTitle}</h5>
                                        <p>{info.featureDesc}</p>
                                    </div>
                                </div>
                            </div>
                    )
                } )
            }

            </div>
        </div>
    </section>
    }
}


export default AmlScreening
