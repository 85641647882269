import React, { Component } from 'react';
import homedata from '../../data/home.json';

class TransactionMonitoring extends Component {
    render() {

        let publicUrl = process.env.PUBLIC_URL

        let transactionMonitoringInfo = homedata.transactionMonitoring
        let sectionHeadingInfo = transactionMonitoringInfo.sectionHeading
        let desc = transactionMonitoringInfo.transactionMonitoringInfo.desc

        const inlineStyle = {
            backgroundImage : 'url('+publicUrl+transactionMonitoringInfo.transactionMonitoringInfo.img+')'
        }

        return <section id="solutions-features-transaction-monitoring" className="pt100 pb100 bg-parallax" style={inlineStyle}>
        <div className="color-overlay opacity-8"></div>
        <div className="container">
            <div className="row">
                <div className="col-md-8 offset-md-2 aboutIntroText color-white text-center">
                    <h2 className="m-30px-b" dangerouslySetInnerHTML={{__html: sectionHeadingInfo.title}}></h2>
                    {
                            desc.map( ( info, i ) => {
                                return(
                                        <p className="m-50px-b md-m-30px-b">
                                        {info}
                                        </p>
                                )
                            } )
                        }					
                </div>
            </div>
        </div>
        <div className="bottom-pattern d-none d-lg-block filter-light-blue" >
            <img src={`${publicUrl}/assets/img/bg-pattern-light.png`} alt="ng pattren" />
        </div>
    </section>
    }
}


export default TransactionMonitoring
