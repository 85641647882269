import React, { Component } from 'react';
import homedata from '../../data/home.json';

class TransactionMonitoringFeature2 extends Component {
    render () {

        let transactionMonitoringInfo = homedata.transactionMonitoring
        let features = transactionMonitoringInfo.features


        return     <section id="solutions-features-transaction-monitoring-2" className="pt100 pb100">
        <div className="container">
            <div className="row">
                <div className="col-md-7 col-12">
                    <div className="section-title mb60">
                        <h2 className="text-left" dangerouslySetInnerHTML={{__html: features[1].featureTitle}}></h2>
                        <hr className="lines text-left"/>
                        
                        {
                            features[1].featureDesc.map( ( info, i ) => {
                                return(
                                        <div className="flex-column h-90">
                                            <div className="media-body m-20px-t">
                                                <p>{info}</p>
                                            </div>
                                        </div>
                                )
                            } )
                        }
                    </div>
                </div>

                <div className="col-md-5 col-12">
                    <div className="">

                    <div className="pt-5 d-flex">
                            <img className="solutions-img m-auto" src={features[1].img} alt={features[1].featureTitle} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
}


export default TransactionMonitoringFeature2
