import React, { Component } from 'react';
import homedata from '../../data/home.json';

class TransactionMonitoringFeature3 extends Component {
    render () {

        let transactionMonitoringInfo = homedata.transactionMonitoring
        let features = transactionMonitoringInfo.features


        return     <section id="solutions-features-transaction-monitoring-3" className="pt100 pb100 bg-light-gray">
        <div className="container">
        <div className="row">
                <div className="col-md-5 col-12">
                    <div className="section-title mb60">
                        <div className="pt-5 d-flex">
                            <img className="solutions-img m-auto" src={features[2].img} alt={features[2].featureTitle} />
                        </div>
                    </div>
                </div>

                <div className="col-md-7 col-12 my-auto">
                    <div className="section-title">
                        <h2 className="text-left" dangerouslySetInnerHTML={{__html: features[2].featureTitle}}></h2>
                        <hr className="lines text-left"/>
                        {
                            features[2].featureDesc.map( ( info, i ) => {
                                return(
                                    <div className="flex-column h-90">
                                    <div className="media-body m-20px-t">
                                        <p>{info}</p>
                                    </div>
                                </div>
                                )
                            } )
                        }

                    </div>
                </div>
            </div>
        </div>
    </section>
    }
}


export default TransactionMonitoringFeature3
