import React, { Component } from 'react';
import homedata from '../../data/home.json';
import { HashLink as HLink } from 'react-router-hash-link';


class TransactionMonitoringFeatures extends Component {

    render () {

        let getSpecialFeatures = homedata.specialFeatures
        let publicUrl = process.env.PUBLIC_URL


        let transactionMonitoringInfo = homedata.transactionMonitoring
        let features = transactionMonitoringInfo.features

        const inlineStyle = {
            backgroundImage : 'url('+publicUrl+getSpecialFeatures.bgImg+')'
        }

        return <section id="transaction-monitoring-features" className="pt50 pb50 bg-parallax" style={inlineStyle}>
        <div className="color-overlay opacity-8"></div>
        <div className="container">
        <div className="row">
                <div className="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">
                    <div className="section-title text-center">
                        <h2 className="text-white"> FEATURES</h2>
                        <hr className="lines"/>
                    </div>
                </div>
            </div>
        <div className="row about-boxes justify-content-center">
                {
                    features.map( ( info, i ) => {
                        return(
                            <div key={i} className="col-lg-3 col-md-6 bounceIn">
                                <div className="media feature-box flex-column h-90 ">
                                    <div className="features-media-icon m-auto d-flex">
                                        <img className="m-auto" src={info.img} alt=""/>
                                    </div>
                                    <div className="media-body m-20px-t mx-auto">
                                        <h5 dangerouslySetInnerHTML={{__html: info.featureTitle}}></h5>
                                        {/* <p>{info.desc}</p> */}
                                    </div>
                                    <div className="download-btn m-auto pt-5">
                                        <HLink className="read-more primary-btn primary-btn-2" smooth  to={info.btnUrl}>{getSpecialFeatures.btnLabel}</HLink>
                                    </div>
                                   
                                </div>
                            </div>
                        )
                    } )
                }

            </div>
        </div>
        <div className="bottom-pattern d-none d-lg-block filter-light-blue" >
            <img src={`${publicUrl}/assets/img/bg-pattern-light.png`} alt="ng pattren" />
        </div>
    </section>
    }
}


export default TransactionMonitoringFeatures
