import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Partners extends Component {
    render() {

        let partnersInfo = homedata.partnersInfo
        let sectionHeadingInfo = partnersInfo.sectionHeading
        let partners = partnersInfo.partners

        return 	<section id="partners" className="pt100 pb100">
		<div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">
                    <div className="section-title text-center mb60">
                        <h2 dangerouslySetInnerHTML= {{__html:sectionHeadingInfo.title }}></h2>
                        <hr className="lines"/>
                    </div>
                </div>
            </div>	
			<div className="d-flex">
                {
                    partners.map( ( element, i ) => {
                        return(
                        <div key={i} className="partners-item text-center mx-4">
                            <div className="icon-outer">
                                <a title={element.brand} className="logo" href="/#">
                                                    <img src={element.logo} alt="Emlo Technologies logo" />
                                                </a>
                            </div>
                            {/* <h4 className="title m-t-30">{element.title}</h4> */}
                        </div>
                        )
                    } )
                }
            </div>
		</div>
	</section>
    }
}


export default Partners
