const env = (process.env.REACT_APP_ENV || "development").trim();
console.log(env); // check env in github build
const envConfig = {
  development: {
    emlo: "//localhost:3000",
    login: "//demo-compliance-app.emlotech.com/login",
    MCAPIUrl: "https://mc.api.stage.emlotech.com",
  },
  staging: {
    emlo: "//demo.emlotech.com",
    login: "//demo-compliance-app.emlotech.com/login",
    MCAPIUrl: "https://mc.api.stage.emlotech.com",
  },
  production: {
    emlo: "//emlotech.com",
    login: "//compliance-app.emlotech.com/login",
    MCAPIUrl: "https://pos-api.emlotech.com",
  },
}[env];
export default envConfig;
