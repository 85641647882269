import React from 'react';
import Hero from './home-components/hero';
import FeaturesSolutions from './home-components/solutions';
import EKYC from './home-components/ekyc';
import AmlScreening from './home-components/aml-screening';
import TransactionMonitoringMoreInfo from './home-components/transaction-monitoring-more-info';
import TransactionMonitoring from './home-components/transaction-monitoring';
import TransactionMonitoringFeatures from './home-components/transaction-monitoring-features';
import TransactionMonitoringFeature1 from './home-components/transaction-monitoring-feature-1';
import TransactionMonitoringFeature2 from './home-components/transaction-monitoring-feature-2';
import TransactionMonitoringFeature3 from './home-components/transaction-monitoring-feature-3';
import TransactionMonitoringFeature4 from './home-components/transaction-monitoring-feature-4';
import About from './home-components/about';
import AboutIntro from './home-components/about-intro';
import AboutBenefit from './home-components/about-benefit';
import Screenshot from './home-components/screenshots';
import Pricing from './home-components/pricing';
import Contact from './home-components/contact';

import Partners from './home-components/partners';


const Home = () => {
    return <div>
        <Hero />
        <FeaturesSolutions/>
        <EKYC/>
        <AmlScreening/>
        <TransactionMonitoring/>
        <TransactionMonitoringMoreInfo/>
        <TransactionMonitoringFeatures/>
        <TransactionMonitoringFeature1/>
        <TransactionMonitoringFeature2/>
        <TransactionMonitoringFeature3/>
        <TransactionMonitoringFeature4/>
        {/* <Features /> */}
        <About />
        <AboutIntro />
        <AboutBenefit />
        <Screenshot />
         {/* <HowWorks />
         <SpecialFeatures />
         <ScreenshotsV2 /> */}
        <Pricing />
        <Partners />
        {/* <FactsSection />
        <Testimonial /> */}
        {/* <Subscribe /> */}
        {/* <VideoPromo /> */}
        {/* <Blog /> */}
        <Contact />
    </div>
}


export default Home
