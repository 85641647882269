import React, { Component } from 'react';
import homedata from '../../data/home.json';

class About extends Component {
    render() {

        let aboutContent = homedata.about
        let sectionHeadingInfo = aboutContent.sectionHeading
        let aboutFeaturesInfo = aboutContent.aboutFeatures
        
        return <section id="about" className="pt100 pb100 bg-light-gray">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">
                    <div className="section-title text-center">
                        <h2 dangerouslySetInnerHTML={{__html: sectionHeadingInfo.title}}></h2>
                        <hr className="lines"/>
                    </div>
                </div>
            </div>
            <div className="row about-boxes">
                {
                    aboutFeaturesInfo.map( ( info, i ) => {
                        return(
                            <div key={i} className="col-lg-12 col-md-12">
                                <div className="media flex-column">
                                    {/* <div className="media-icon m-auto">
                                        <i className={info.icon}></i>
                                    </div> */}
                                    <div className="media-body m-20px-t m-20px-b aboutIntroText">
                                        <a title="Emlo Technologies" className="logo" href="https://www.emlotech.com/">
                                            <img src="/assets/img/logo.png" alt="Emlo Technologies logo" />
                                        </a>
                                        <p className="mt-5" >{info.desc}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    } )
                }

            </div>
        </div>
    </section>
    }
}


export default About
